import React from "react";
import {Link} from "gatsby"

const Back = props => (
       
        <Link to ="/" 
        class="transform transition duration-500 ease-in-out navigation object-center flex bg-main-text py-3 px-5 text-secondary shadow-lg float-left font-bold hover:shadow-2xl hover:opacity-90 rounded-full">
        <svg class="toggle-light mr-2" width="20" height="23" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.46967 5.96967C0.176777 6.26256 0.176777 6.73744 0.46967 7.03033L5.24264 11.8033C5.53553 12.0962 6.01041 12.0962 6.3033 11.8033C6.59619 11.5104 6.59619 11.0355 6.3033 10.7426L2.06066 6.5L6.3033 2.25736C6.59619 1.96447 6.59619 1.48959 6.3033 1.1967C6.01041 0.903806 5.53553 0.903806 5.24264 1.1967L0.46967 5.96967ZM17 5.75L1 5.75V7.25L17 7.25V5.75Z" fill="#F4F4F4" />
        </svg>

        <svg class="toggle-dark mr-2" width="20" height="23" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.46967 5.96967C0.176777 6.26256 0.176777 6.73744 0.46967 7.03033L5.24264 11.8033C5.53553 12.0962 6.01041 12.0962 6.3033 11.8033C6.59619 11.5104 6.59619 11.0355 6.3033 10.7426L2.06066 6.5L6.3033 2.25736C6.59619 1.96447 6.59619 1.48959 6.3033 1.1967C6.01041 0.903806 5.53553 0.903806 5.24264 1.1967L0.46967 5.96967ZM17 5.75L1 5.75V7.25L17 7.25V5.75Z" fill="#27282C" />
        </svg>
        <div class=" ">{props.where}</div>

        </Link>
    )
export default Back

