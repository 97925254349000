import React from "react"
import Scrollspy from "react-scrollspy"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Footer from "../components/footer"
import Recommendations from "../components/recommendations"
import { Link } from "gatsby"
import Preview from '../components/Preview'
import Back from "../components/back"


const Trustpilot = props => (
    <Layout>
        <Preview />

        <Hero
            title="Trustpilot integrations"
            summary="Designing plug and play integrations to enable businesses automatically collect and showcase customer reviews. 🔌"
            cover=""
        />
        <div class="divide-y divide-divider md:px-40">
            <div class="md:flex md:flex-row">

                <Link to="#section-1" class="hover:opacity-80 flex-1">
                    <div class="md:grid gap-6 py-4 px-6 md:px-0 flex-1">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="32" cy="32" r="31.4325" fill="#F8F8F8" stroke="#DCDCE6" stroke-width="1.13499" />
                            <rect width="41.4815" height="28.4444" transform="translate(11.2592 17.7778)" fill="white" fill-opacity="0.01" />
                            <path d="M40.6815 18.9629H23.3209C22.1651 18.9629 21.0566 19.422 20.2394 20.2393C19.4221 21.0566 18.963 22.165 18.963 23.3208V40.6814C18.963 41.8372 19.4221 42.9457 20.2394 43.7629C21.0566 44.5802 22.1651 45.0393 23.3209 45.0393H40.6815C41.2538 45.0394 41.8205 44.9267 42.3493 44.7078C42.878 44.4888 43.3585 44.1678 43.7632 43.7631C44.1678 43.3584 44.4888 42.878 44.7078 42.3492C44.9268 41.8204 45.0395 41.2537 45.0394 40.6814V23.3208C45.0395 22.7485 44.9269 22.1817 44.708 21.6529C44.489 21.1241 44.168 20.6437 43.7633 20.239C43.3586 19.8342 42.8782 19.5133 42.3494 19.2943C41.8206 19.0753 41.2538 18.9627 40.6815 18.9629V18.9629ZM40.2987 38.9244C40.2987 39.289 40.1538 39.6387 39.896 39.8965C39.6382 40.1543 39.2885 40.2992 38.9238 40.2992H25.0815C24.9009 40.2993 24.722 40.2638 24.5552 40.1947C24.3883 40.1256 24.2367 40.0244 24.1089 39.8967C23.9812 39.7691 23.8799 39.6175 23.8108 39.4506C23.7416 39.2838 23.7061 39.105 23.7061 38.9244V25.0814C23.706 24.9008 23.7415 24.7219 23.8106 24.555C23.8797 24.3881 23.981 24.2364 24.1087 24.1087C24.2365 23.9809 24.3881 23.8796 24.555 23.8105C24.7219 23.7414 24.9008 23.7059 25.0815 23.706H38.9238C39.1044 23.706 39.2833 23.7416 39.4501 23.8107C39.6169 23.8798 39.7685 23.9812 39.8962 24.1089C40.0239 24.2366 40.1251 24.3882 40.1942 24.5551C40.2632 24.722 40.2987 24.9008 40.2987 25.0814V38.9244Z" fill="black" />
                            <path d="M29.2343 35.5574C28.7982 35.5574 28.445 35.2018 28.445 34.7639V29.2386C28.445 28.8006 28.7982 28.4451 29.2343 28.4451H34.768C35.2053 28.4451 35.5573 28.8006 35.5573 29.2386V34.7639C35.5573 35.2018 35.2053 35.5574 34.768 35.5574H29.2343Z" fill="black" />
                        </svg>


                        <div class="flex flex-col gap-2">

                            <div class="text-left text-xl font-semibold">Square</div>
                            <div class="text-left md:w-11/12 pb-4 md:pb-0">An integration to enable businesses automatically collect service reviews from their customers after every online or PoS purchase.</div>

                        </div>


                    </div>
                </Link>

                <Link to="#section-1" class="hover:opacity-80 flex-1">
                    <div class="md:grid gap-6 py-4 px-6 md:px-0">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="32" cy="32" r="31.4325" fill="#F8F8F8" stroke="#DCDCE6" stroke-width="1.13499" />
                            <path d="M34.7164 25.4186C34.008 25.7845 33.7302 26.4017 33.7302 28.1057C33.9916 27.8751 34.2952 27.6953 34.6247 27.5759C34.9536 27.4578 35.264 27.2951 35.5471 27.0926C36.1472 26.6556 36.2305 26.0986 36.2305 25.1619C36.2305 25.1619 35.2609 25.1373 34.7164 25.4186V25.4186Z" fill="#1D1D1B" />
                            <path d="M30.5076 25.8172C30.1362 26.1721 29.8759 26.6241 29.7575 27.1198L27.8406 34.4163L26.2487 28.4578C26.1004 27.7489 25.8012 27.0789 25.3708 26.4917C24.8152 25.8172 23.6595 25.7735 23.5373 25.7735C23.415 25.7735 22.2677 25.8172 21.7037 26.4917C21.2733 27.0789 20.9741 27.7489 20.8258 28.4578L19.2312 34.4163L17.3143 27.1198C17.1975 26.6241 16.938 26.1719 16.567 25.8172C15.628 25.0034 14.2222 25.1809 14.2222 25.1809L17.8949 38.8183C17.8949 38.8183 19.1062 38.9056 19.7118 38.5998C20.5063 38.2011 20.8842 37.8953 21.3787 36.0384C21.7843 34.3835 22.9928 29.5228 23.1067 29.1787C23.1622 29.0067 23.2317 28.5971 23.5373 28.5971C23.8429 28.5971 23.9123 29.0067 23.9679 29.1787C24.079 29.5255 25.2791 34.3835 25.7098 36.0384C26.1904 37.8953 26.571 38.2011 27.3766 38.5998C27.9823 38.9056 29.1935 38.8183 29.1935 38.8183L32.8523 25.1809C32.8523 25.1809 31.4466 25.0034 30.5076 25.8172Z" fill="#1D1D1B" />
                            <path d="M36.2305 27.4065C36.0301 27.6703 35.773 27.8875 35.4776 28.0428C35.1415 28.2257 34.8192 28.3513 34.472 28.5152C33.8997 28.7882 33.7302 29.0941 33.7302 29.5501V38.8183C33.7302 38.8183 34.6581 38.933 35.2637 38.6298C36.0416 38.2393 36.2222 37.8625 36.2277 36.1722V27.9117L36.2305 27.4065Z" fill="#1D1D1B" />
                            <path d="M45.3983 32.0324L50.0433 25.2246C50.0433 25.2246 48.0792 24.8942 47.1068 25.7708C46.618 26.2485 46.1761 26.7706 45.7872 27.33L44.0731 29.7577C44.0225 29.914 43.8748 30.0202 43.7078 30.0202C43.5409 30.0202 43.3931 29.914 43.3425 29.7577L41.6284 27.33C41.2385 26.7713 40.7968 26.2493 40.3088 25.7708C39.3364 24.8997 37.3695 25.2246 37.3695 25.2246L42.0284 32.0324L37.3834 38.8183C37.3834 38.8183 39.4309 39.0722 40.4032 38.2011C40.8625 37.7566 41.2731 37.266 41.6284 36.7374L43.3425 34.3098C43.3931 34.1534 43.5409 34.0473 43.7078 34.0473C43.8748 34.0473 44.0225 34.1534 44.0731 34.3098L45.7872 36.7374C46.1596 37.2634 46.5819 37.7535 47.0485 38.2011C48.0208 39.0722 50.0322 38.8183 50.0322 38.8183L45.3983 32.0324Z" fill="#1D1D1B" />
                        </svg>


                        <div class="flex flex-col gap-2">

                            <div class="text-left text-xl font-semibold">Wix</div>
                            <div class="text-left md:w-11/12 pb-4 md:pb-0">An integration to enable businesses automatically collect service reviews after an online purchase.</div>

                        </div>


                    </div>
                </Link>

            </div>

            <div class="md:flex md:flex-row">


                <Link to="#section-1" class="hover:opacity-80 flex-1">
                    <div class="md:grid gap-6 py-4 px-6 md:px-0">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="32" cy="32" r="31.4325" fill="#F8F8F8" stroke="#DCDCE6" stroke-width="1.13499" />
                            <rect width="41.4815" height="28.4444" transform="translate(11.2593 17.7778)" fill="white" fill-opacity="0.01" />
                            <path d="M34.4089 42.8565L30.1917 38.7441L39.2488 29.4951L43.6152 33.7745L34.4089 42.8565Z" fill="#8AB4F8" />
                            <path d="M34.4269 24.6747L30.2248 20.3842L21.0365 29.4855C19.8521 30.67 19.8521 32.5902 21.0365 33.776L30.0868 42.8856L34.3662 38.8823L27.4708 31.6308L34.4269 24.6747Z" fill="#4285F4" />
                            <path d="M43.5544 29.5559L34.4435 20.4449C33.2577 19.2591 31.3347 19.2591 30.1489 20.4449C28.9631 21.6307 28.9631 23.5537 30.1489 24.7395L39.2599 33.8505C40.4457 35.0363 42.3686 35.0363 43.5544 33.8505C44.7403 32.6647 44.7403 30.7417 43.5544 29.5559Z" fill="#8AB4F8" />
                            <path d="M32.2265 43.8518C33.8656 43.8518 35.1944 42.523 35.1944 40.8839C35.1944 39.2447 33.8656 37.9159 32.2265 37.9159C30.5873 37.9159 29.2585 39.2447 29.2585 40.8839C29.2585 42.523 30.5873 43.8518 32.2265 43.8518Z" fill="#246FDB" />
                        </svg>



                        <div class="flex flex-col gap-2">

                            <div class="text-left text-xl font-semibold">Google Tag Manager</div>
                            <div class="text-left md:w-11/12 pb-4 md:pb-0">An integration to enable businesses add a Trustpilot widget to their website.</div>

                        </div>


                    </div></Link>

                <Link to="#section-1" class="hover:opacity-80 flex-1">
                    <div class="md:grid gap-6 py-4 px-6 md:px-0">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="32" cy="32" r="31.4325" fill="#F8F8F8" stroke="#DCDCE6" stroke-width="1.13499" />
                            <rect width="41.4815" height="28.4444" transform="translate(11.2592 17.7778)" fill="white" fill-opacity="0.01" />
                            <g clip-path="url(#clip0_3891_13195)">
                                <path d="M26.8686 43.6007L27.3097 40.8609L26.3272 40.8386H21.6355L24.896 20.6212C24.9061 20.5602 24.939 20.5032 24.9871 20.4628C25.0352 20.4224 25.0967 20.4001 25.1608 20.4001H33.0716C35.6979 20.4001 37.5103 20.9346 38.4566 21.9895C38.9002 22.4843 39.1827 23.0015 39.3193 23.5705C39.4627 24.1677 39.4652 24.8811 39.3252 25.7512L39.3151 25.8147V26.3723L39.7587 26.618C40.1324 26.8119 40.4292 27.0337 40.6569 27.2877C41.0364 27.7109 41.2819 28.2486 41.3856 28.8861C41.4927 29.5418 41.4573 30.3221 41.2819 31.2054C41.0795 32.2215 40.7522 33.1065 40.3103 33.8306C39.9038 34.4978 39.386 35.0512 38.7712 35.4801C38.1842 35.8876 37.4867 36.1968 36.6981 36.3948C35.934 36.5894 35.0628 36.6876 34.1073 36.6876H33.4916C33.0514 36.6876 32.6238 36.8426 32.2882 37.1206C31.9517 37.4043 31.729 37.7919 31.6607 38.2159L31.6143 38.4625L30.835 43.2915L30.7996 43.4688C30.7903 43.5249 30.7743 43.5529 30.7507 43.5719C30.7296 43.5892 30.6992 43.6007 30.6697 43.6007H26.8686V43.6007Z" fill="#253B80" />
                                <path d="M40.1788 25.879C40.1552 26.0267 40.1282 26.1776 40.0978 26.3326C39.0546 31.5707 35.4854 33.3803 30.927 33.3803H28.606C28.0486 33.3803 27.5788 33.7761 27.4919 34.3139L26.3036 41.684L25.9671 43.7731C25.9106 44.1261 26.1889 44.4445 26.5533 44.4445H30.6698C31.1572 44.4445 31.5713 44.0981 31.6481 43.6279L31.6886 43.4234L32.4636 38.6134L32.5134 38.3495C32.5893 37.8777 33.0042 37.5313 33.4917 37.5313H34.1073C38.0956 37.5313 41.2178 35.9477 42.1303 31.3654C42.5115 29.4511 42.3142 27.8527 41.3055 26.7285C41.0002 26.3896 40.6215 26.1083 40.1788 25.879V25.879Z" fill="#179BD7" />
                                <path d="M39.0874 25.4534C38.928 25.408 38.7635 25.3668 38.5949 25.3296C38.4253 25.2933 38.2516 25.2612 38.0728 25.2331C37.447 25.1342 36.7614 25.0872 36.0268 25.0872H29.8263C29.6737 25.0872 29.5286 25.121 29.3987 25.182C29.1128 25.3164 28.9003 25.5812 28.8489 25.9053L27.5298 34.0754L27.4919 34.3138C27.5787 33.776 28.0485 33.3802 28.606 33.3802H30.9269C35.4854 33.3802 39.0545 31.5698 40.0978 26.3326C40.129 26.1775 40.1551 26.0266 40.1787 25.8789C39.9147 25.742 39.6288 25.6249 39.321 25.5251C39.2451 25.5004 39.1667 25.4764 39.0874 25.4534V25.4534Z" fill="#222D65" />
                                <path d="M28.8489 25.9054C28.9003 25.5813 29.1129 25.3165 29.3988 25.1829C29.5295 25.1219 29.6737 25.0881 29.8264 25.0881H36.0268C36.7614 25.0881 37.4471 25.1351 38.0728 25.234C38.2516 25.2621 38.4254 25.2942 38.5949 25.3305C38.7636 25.3677 38.928 25.4089 39.0874 25.4543C39.1667 25.4773 39.2451 25.5013 39.3219 25.5252C39.6297 25.625 39.9156 25.7429 40.1796 25.879C40.4899 23.9433 40.1771 22.6253 39.1068 21.4319C37.9269 20.118 35.7974 19.5555 33.0725 19.5555H25.1617C24.605 19.5555 24.1302 19.9514 24.0442 20.49L20.7491 40.9153C20.6842 41.3194 21.003 41.6839 21.4196 41.6839H26.3036L27.5299 34.0755L28.8489 25.9054V25.9054Z" fill="#253B80" />
                            </g>
                            <defs>
                                <clipPath id="clip0_3891_13195">
                                    <rect width="21.5704" height="24.8889" fill="white" transform="translate(20.7407 19.5555)" />
                                </clipPath>
                            </defs>
                        </svg>



                        <div class="flex flex-col gap-2">

                            <div class="text-left text-xl font-semibold">PayPal</div>
                            <div class="text-left md:w-11/12 pb-4 md:pb-0">An integration to enable businesses automatically collect service reviews from their customers after an invoice is generated.</div>

                        </div>


                    </div>
                </Link>



            </div>

            <div class="md:flex md:flex-row">
                <Link to="#section-1" class="hover:opacity-80 flex-1">
                    <div class="md:grid gap-6 py-4 px-6 md:px-0">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="32" cy="32" r="31.4325" fill="#F8F8F8" stroke="#DCDCE6" stroke-width="1.13499" />
                            <rect width="41.4815" height="28.4444" transform="translate(11.2592 17.7778)" fill="white" fill-opacity="0.01" />
                            <g clip-path="url(#clip0_3891_13197)">
                                <path d="M44.4444 32.0001C44.4444 25.1272 38.8729 19.5556 32 19.5556C25.1271 19.5556 19.5555 25.1272 19.5555 32.0001C19.5555 38.2115 24.1063 43.3598 30.0555 44.2933V35.5973H26.8958V32.0001H30.0555V29.2584C30.0555 26.1395 31.9134 24.4167 34.756 24.4167C36.1175 24.4167 37.5417 24.6598 37.5417 24.6598V27.7223H35.9724C34.4265 27.7223 33.9444 28.6816 33.9444 29.6657V32.0001H37.3958L36.8441 35.5973H33.9444V44.2933C39.8937 43.3598 44.4444 38.2115 44.4444 32.0001Z" fill="#1877F2" />
                                <path d="M36.8441 35.5972L37.3958 32H33.9444V29.6656C33.9444 28.6815 34.4265 27.7222 35.9724 27.7222H37.5416V24.6597C37.5416 24.6597 36.1175 24.4167 34.756 24.4167C31.9134 24.4167 30.0555 26.1395 30.0555 29.2583V32H26.8958V35.5972H30.0555V44.2933C30.6891 44.3927 31.3385 44.4445 32 44.4445C32.6615 44.4445 33.3108 44.3927 33.9444 44.2933V35.5972H36.8441Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_3891_13197">
                                    <rect width="24.8889" height="24.8889" fill="white" transform="translate(19.5555 19.5555)" />
                                </clipPath>
                            </defs>
                        </svg>


                        <div class="flex flex-col gap-2">

                            <div class="text-left text-xl font-semibold">Facebook</div>
                            <div class="text-left md:w-11/12 pb-4 md:pb-0">An integration to enable businesses display their Trustpilot rating and reviews on their Facebook and Instagram page.</div>

                        </div>


                    </div>
                </Link>

                <Link to="#section-1" class="hover:opacity-80 flex-1">
                    <div class="md:grid gap-6 py-4 px-6 md:px-0">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="32" cy="32" r="31.4325" fill="#F8F8F8" stroke="#DCDCE6" stroke-width="1.13499" />
                            <rect width="41.4815" height="28.4444" transform="translate(11.2592 17.7778)" fill="white" fill-opacity="0.01" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.2042 23.0894C41.3077 23.0981 41.4328 23.1772 41.4525 23.3201H41.4526C41.472 23.4629 44.5556 44.3111 44.5556 44.3111L35.7713 46.2114L19.5593 43.1739C19.5593 43.1739 21.6398 27.0841 21.7184 26.5168C21.8228 25.7675 21.848 25.7425 22.6431 25.4931C22.7724 25.4525 23.7844 25.1386 25.2089 24.6972C25.7581 22.3683 27.5095 17.7854 31.1756 17.7854C31.6545 17.7854 32.2089 18.0426 32.6565 18.6345C32.7012 18.6319 32.7459 18.6302 32.7894 18.6302C34.3631 18.6302 35.2577 19.9706 35.7646 21.4291C36.2822 21.269 36.5952 21.1724 36.6138 21.1669C36.7401 21.1297 37.0655 21.079 37.2322 21.2457C37.3987 21.4124 38.9161 22.9189 38.9161 22.9189C38.9161 22.9189 41.101 23.0808 41.2042 23.0894ZM33.4967 22.1309L34.8685 21.7063C34.521 20.6445 33.9809 19.7218 33.17 19.6201C33.3719 20.1987 33.4977 20.9286 33.4977 21.8351C33.4977 21.9375 33.4972 22.0312 33.4968 22.1263V22.1265V22.1266L33.4967 22.1309ZM32.1641 19.7998C32.3895 20.3647 32.5361 21.1754 32.5361 22.2694C32.5361 22.3248 32.5356 22.3754 32.5352 22.4267V22.4267L32.5351 22.4283L31.2934 22.8128L29.702 23.3056C30.2475 21.2002 31.2701 20.1834 32.1641 19.7998ZM31.5451 18.9274C31.3925 18.8224 31.2331 18.7687 31.0749 18.7687H31.0748C28.4416 18.7687 26.9625 22.226 26.3395 24.347L28.579 23.6534C29.1108 20.8727 30.3702 19.4803 31.5451 18.9274Z" fill="#95BF46" />
                            <path d="M41.2042 23.0895C41.101 23.0809 38.9161 22.9189 38.9161 22.9189C38.9161 22.9189 37.3987 21.4125 37.2322 21.2457C37.1699 21.1837 37.0858 21.1519 36.9979 21.1382L35.7721 46.2113L44.5556 44.3112C44.5556 44.3112 41.4719 23.463 41.4525 23.3201C41.4328 23.1772 41.3077 23.0982 41.2042 23.0895" fill="#5E8E3E" />
                            <path d="M32.7894 27.9432L31.7062 31.1651C31.7062 31.1651 30.7572 30.6586 29.594 30.6586C27.8886 30.6586 27.8028 31.7288 27.8028 31.9985C27.8028 33.47 31.6386 34.0338 31.6386 37.4806C31.6386 40.1924 29.9186 41.9386 27.5995 41.9386C24.8166 41.9386 23.3934 40.2066 23.3934 40.2066L24.1386 37.7446C24.1386 37.7446 25.6015 39.0005 26.8359 39.0005C27.6425 39.0005 27.9706 38.3655 27.9706 37.9014C27.9706 35.982 24.8236 35.8963 24.8236 32.7423C24.8236 30.0876 26.729 27.5187 30.5752 27.5187C32.0572 27.5187 32.7894 27.9432 32.7894 27.9432" fill="white" />
                        </svg>



                        <div class="flex flex-col gap-2">

                            <div class="text-left text-xl font-semibold">Shopify</div>
                            <div class="text-left md:w-11/12 pb-4 md:pb-0">An integration to enable businesses automatically collect reviews from their customers and drag + drop Trustpilot widgets on their website.</div>

                        </div>


                    </div>
                </Link>

            </div>

        </div>

        <div id="section-1"
            data-sal="slide-down"
            data-sal-duration="900"
            data-sal-delay="200"
            data-sal-easing="ease" class="flex flex-col justify-items-center md:w-9/12 mx-auto py-16 px-4 bg-secondary bg-opacity-30 mt-20">

            <p class="subpixel-antialiased text-2xl md:text-4xl font-semibold text-center text-orange-600 leading-normal md:leading-tight">Full case study will be updated soon</p>
            <p class="subpixel-antialiased text-base md:text-base font-normal text-center md:w-7/12 mx-auto py-4">I am working super hard to update this case study and take you through the process of designing these integrations, please check back later.</p>
            <div class="mx-auto mt-8">


                <Back where="Go back home" />


            </div>
        </div>
        <Recommendations projectName="chrome" />
        <Footer />
    </Layout>
)

export default Trustpilot
